import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from 'src/components/common';
import { Entry } from 'src/components/post';
import type { PageProps } from 'gatsby';
import type { GetPostQuery } from 'types/graphql-type';
import type { PagePostSingleContextType } from 'types/custom-type';

type Props = PageProps<GetPostQuery, PagePostSingleContextType>;

export const query = graphql`
  query GetPost($uid: String!) {
    seo: prismicPost(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyExcerptBlock {
            ...PostSliceZoneExcerpt
          }
        }
      }
    }
    post: prismicPost(uid: { eq: $uid }) {
      data {
        ...PostEntryData
      }
    }
  }
`;

const Post: React.FC<Props> = ({
  data: { seo, post },
  location: { pathname },
}) => {
  let description: string | undefined | null;
  if (seo?.data?.body) {
    const excerpt = seo?.data?.body?.find(
      (element) => element?.slice_type === 'excerpt_block'
    );
    if (excerpt) {
      description = excerpt?.primary?.block_content?.text;
    }
  }
  return (
    <>
      <SEO
        title={seo?.data?.title?.text ?? undefined}
        pathname={pathname}
        description={description}
        image={
          seo?.data?.image?.localFile?.childImageSharp?.original?.src ??
          undefined
        }
      />
      <Entry {...post?.data} />
    </>
  );
};

export default Post;
